<template>
  <div
    class="views_shopping_mail_task_index"
  >
    <div class="input_group d-flex align-center mt-20"
      v-if="open_search"
    >
      <search-module
        class="mr-10"
        :props_keyword="props_keyword"
        @handleChange="changeKeyword"
      ></search-module>
      <div class="button search"
        @click.stop="handleSearchButton();"
      >搜索</div>
    </div>
    <category-filter
      class="mt-10"
      :only="only"
      :type_value="props_type"
      :price_type="props_price_type"
      @handlePrice="handlePrice"
      @handleType="handleType"
    ></category-filter>
    <result-page
      :type="type"
      :package_list="package_list"
      :task_list="task_list"
    ></result-page>
    <paging></paging>

    <div class="load_more cp un_sel d-flex align-center justify-center"
      v-if="load_more"
      @click.stop="loadingMore();"
    >
      <loading-icon
        v-if="loading"
        size="20px"
      ></loading-icon>
      <span
        v-if="!nomore && !loading"
      >加载更多</span>
    </div>
    <div class="nomore"
      v-if="nomore"
    >————  已经到底啦  ————</div>

  </div>
</template>

<script>
import SearchModule from '@/views/ShoppingMail/Modules/Common/Search.vue';
import CategoryFilter from '@/views/ShoppingMail/Modules/Common/CategoryFilter/index.vue';
import ResultPage from './Result.vue';
import Paging from './Paging.vue';
import { PPackage, PTask} from '@/types';
import LoadingIcon from '@/components/basic/loadingIcon.vue';
export default {
  components: {
    SearchModule,
    CategoryFilter,
    ResultPage,
    Paging,
    LoadingIcon,
  },
  props: {
    open_search: {
      type: Boolean,
      default: false,
    },
    // style: {
    //   type: String,
    //   default: undefined, // task 任务 teaching_package 教学包
    // },
    init_all: {
      type: Boolean,
      default: false, // 初始加载全部
    },
    loading_init: {
      type: Boolean,
      default: true, // 初始加载全部
    },
    only: {
      type: Boolean,
      default: undefined,
    },
    props_type: {
      type: String,
      default: undefined,
    },
    props_price_type: {
      type: Number,
      default: undefined,
    },
    props_keyword: {
      type: String,
      default: undefined,
    },

  },
  watch: {
    loading_init: async function(val){
      if(val){
        this.init();
      }
    }
  },
  computed: {
    nomore(){
      const {type, package_list, nomore_package, task_list, nomore_task} = this;
      if(type === 'teaching_package'){
        if(nomore_package){
          return true;
        }
      }
      if(type === 'task'){
        if(nomore_task){
          return true;
        }
      }
      return false;
    },

  },
  data(){
    return{
      type          : 'teaching_package',   // task
      loading       : false,
      package_list  : [],
      nomore_package: false,
      task_list     : [],
      nomore_task   : false,
      keyword       : '',
      price_type    : undefined,            //0免费 1付费
      load_more     : false,
    }
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){
      const {init_all, props_type, props_price_type} = this;
      if(props_type){
        this.type= props_type;
      }
      this.price_type = props_price_type;
      this.doClear();
      this.$nextTick(function(){
        this.doSearch();
      })
    },
    async doSearch(){
      const {type, nomore_package, nomore_task} = this;
      this.saveSearchData();
      this.loading = true;
      if(type === 'teaching_package' && !nomore_package){
        await this.searchTeachingPackage();
      }
      if(type === 'task' && !nomore_task){
        await this.searchTask();
      }
      this.$api.shopping_mail.changeSearchFilterData({loading_init: false});
      this.loading = false;
    },
    async loadingMore(){
      await this.doSearch();
    },
    async searchTeachingPackage(){
      const {package_list, keyword, price_type} = this;
      const res = await this.$global.doPost({
        url: '/projectShop/shopPackages',
        data: {
          keyword,
          priceFlag: price_type,
          start: package_list.length,
        }
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {shoppackages} = contents;
        if(shoppackages&& shoppackages.length){
          const type_list = this.$types.transformObjectForList({
            list: shoppackages,
            obj: PPackage,
          })
          this.$set(this, 'package_list', [
              ...new Set([
                ...package_list,
                ...type_list,
              ])
            ]
          )
        }
        if(!shoppackages || shoppackages.length < 20){
          this.nomore_package = true;
        }
        if(shoppackages && shoppackages.length == 20){
          this.load_more = true;
        }
      }
      return;
    },
    async searchTask(){
      const {task_list, keyword, price_type} = this;

      const res = await this.$global.doPost({
        url: '/projectShop/shopTasks',
        data: {
          keyword,
          priceFlag: price_type,
          start: task_list.length,
        }
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {shoptasks} = contents;
        if(shoptasks&& shoptasks.length){
          const type_list = this.$types.transformObjectForList({
            list: shoptasks,
            obj: PTask,
          })
          this.$set(this, 'task_list', [
              ...new Set([
                ...task_list,
                ...type_list,
              ])
            ]
          )
        }
        console.log(shoptasks, shoptasks.length)
        if(!shoptasks || shoptasks.length < 20){
          this.nomore_task = true;
        }
        if(shoptasks && shoptasks.length == 20){
          this.load_more = true;
        }
      }
      return;

    },
    async doClear(){
      this.$set(this, 'package_list', []);
      this.$set(this, 'task_list', []);
      this.nomore_package = false;
      this.nomore_task = false;
    },
    async handlePrice(price_type){

      // console.log(price_type);
      this.price_type = price_type;
      this.doClear();
      this.$nextTick(async function(){
        await this.doSearch();
      })
    },
    async handleType(type){
      this.type = type;
      this.doClear();
      this.$nextTick(async function(){
        await this.doSearch();
      })
    },
    async changeKeyword(params){
      const {keyword, do_search} = params;
      this.keyword = keyword;
      this.doClear();
      if(do_search){
        this.$nextTick(async function(){
          await this.doSearch();
        })
      }
    },
    /**
     * 保存搜索数据
     */
    saveSearchData(){
      const {price_type, type, only, open_search, keyword} = this;
      this.$api.shopping_mail.changeSearchFilterData({
        price_type, type, keyword
      })
    },
    handleSearchButton(){
      this.doClear();
      this.$nextTick(async function(){
        await this.doSearch();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_task_index{
  width: 100%;
  .input_group{
    .button.search{
      line-height: 40px;
      background: #0054A7;
      border-radius: 4px;
      color: #fff;
      padding: 0 30px;
    }
  }

  .nomore{
    width: 100%;
    font-size: 16px;
    color: #D5D9DB;
    line-height: 21px;
    text-align: center;
    padding: 50px 0;
  }
  .load_more{
    width: 100%;
    font-size: 16px;
    color: $main_blue;
    line-height: 21px;
    text-align: center;
    height: 100px;
  }
}
</style>
