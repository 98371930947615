<template>
  <search-page
    class="views_shopping_mail_pages_search bbox"
    :open_search="open_search"
    :only="only"
    :props_type="type"
    :init_all="init_all"
    :props_price_type="price_type"
    :props_keyword="keyword"
    :loading_init="loading_init"
  ></search-page>
</template>

<script>
import SearchPage from '@/views/ShoppingMail/Search/index.vue';
export default {
  components: {
    SearchPage,
  },
  computed: {
    search_data(){
      return this.$store.state.spm_search_filter_data;
    },
    only(){
      const {search_data} = this;
      return search_data?.only ?? undefined;
    },
    type(){
      const {search_data} = this;
      return search_data?.type ?? undefined;
    },
    price_type(){
      const {search_data} = this;
      return search_data?.price_type ?? undefined;
    },
    open_search(){
      const {search_data} = this;
      return search_data?.open_search ?? undefined;
    },
    init_all(){
      const {search_data} = this;
      return search_data?.init_all ?? true;
    },
    keyword(){
      const {search_data} = this;
      return search_data?.keyword ?? undefined;
    },
    loading_init(){
      const {search_data} = this;
      return search_data?.loading_init ?? undefined;
    },
  },
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_pages_search{
  width: 100%;
  padding: {
    left: 40px;
    right: 40px;
  }
}
</style>

