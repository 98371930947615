var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("search-page", {
    staticClass: "views_shopping_mail_pages_search bbox",
    attrs: {
      open_search: _vm.open_search,
      only: _vm.only,
      props_type: _vm.type,
      init_all: _vm.init_all,
      props_price_type: _vm.price_type,
      props_keyword: _vm.keyword,
      loading_init: _vm.loading_init,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }