var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_task_result bbox d-flex align-start flex-wrap",
    },
    [
      _vm.type === "teaching_package"
        ? [
            _vm._l(_vm.package_list, function (item, index) {
              return [
                _c("teaching-package", {
                  key: index,
                  staticClass: "teaching_package mb-10",
                  attrs: { ppackage: item },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clickPackage(item)
                    },
                  },
                }),
              ]
            }),
          ]
        : _vm._e(),
      _vm.type === "task"
        ? [
            _vm._l(_vm.task_list, function (item, index) {
              return [
                _c("task-module", {
                  key: index,
                  staticClass: "mr-16 task_module mb-16",
                  attrs: { ptask: item },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.viewTask(item)
                    },
                  },
                }),
              ]
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }