<template>
  <div
    class="views_shopping_mail_modules_common_category_filter_index bbox"
  >
    <div class="filter_group d-flex align-center flex-wrap"
      v-if="!only"
    >
      <div class="name">分类：</div>
      <div class="list_group d-flex align-center">
        <template
          v-for="(item,index) in type"
        >
          <div
            :key="index"
            class="item"
            :class="{active: type_value === item.value}"
            @click.stop="changeType(item)"
          >{{item.name}}</div>
        </template>
      </div>
    </div>
    <div class="filter_group d-flex align-center flex-wrap"
      v-if="0"
    >
      <div class="name">课程：</div>
      <div class="list_group d-flex align-center">
        <template
          v-for="(item,index) in course"
        >
          <div
            :key="index"
            class="item"
            :class="{active: course_value === item.value}"
            @click.stop="course_value=item.value"
          >{{item.name}}</div>
        </template>
      </div>
    </div>
    <div class="filter_group d-flex align-center flex-wrap"
      v-if="0"
    >
      <div class="name">岗位：</div>
      <div class="list_group d-flex align-center">
        <template
          v-for="(item,index) in post"
        >
          <div
            :key="index"
            class="item"
            :class="{active: post_value === item.value}"
            @click.stop="post_value=item.value"
          >{{item.name}}</div>
        </template>
      </div>
    </div>
    <div class="filter_group d-flex align-center">
      <div class="name">价格：</div>
      <div class="list_group d-flex align-center flex-wrap">
        <template
          v-for="(item,index) in price"
        >
          <div
            :key="index"
            class="item"
            :class="{active: price_type === item.value}"
            @click.stop="changePrice(item);"
          >{{item.name}}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    only: {
      type: Boolean,
      default: undefined,
    },
    type_value: {
      type: String,
      default: undefined,
    },
    price_type: {
      type: Number,
      default: undefined,
    },

  },
  data(){
    return {
      type: [
        {name: '教学包', value: 'teaching_package'},
        {name: '任务模板', value: 'task'},
      ],
      // type_value: 'teaching_package',
      course: [
        {name: '全部', value: 'all'},
        {name: '会展策划', value: 'hzch'},
        {name: '设计', value: 'sj'},
        {name: '教育学', value: 'jyx'},
      ],
      course_value: 'all',
      post: [
        {name: '全部', value: 'all'},
        {name: '会展策划', value: 'hzch'},
        {name: '策划', value: 'ch'},
      ],
      post_value: 'all',
      price: [
        {name: '全部', value: undefined},
        {name: '免费', value: 0},
        {name: '付费', value: 1},
      ],
      // price_value: undefined,
    }
  },
  mounted(){
    if(this.props_type){
      this.type_value = this.props_type;
    }
  },
  methods: {
    changePrice(item){
      if(this.price_type === item.value){
        return ;
      }
      const {value} = item;
      this.$emit('handlePrice', value);
    },
    changeType(item){
      if(this.type_value === item.value){
        return ;
      }
      const {value} = item;
      // this.type_value = value;
      this.$emit('handleType', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_common_category_filter_index{
  font-size: 14px;
  color: #333333;
  line-height: 19px;
  .filter_group{
    margin-bottom: 6px;
    .item,
    .name{
      @include transition;
      margin-right: 16px;
      margin-bottom: 6px;
      &.active{
        color: $main_blue;
      }
    }
    .item{
      cursor: pointer;
    }
  }
}
</style>
