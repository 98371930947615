var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_task_index" },
    [
      _vm.open_search
        ? _c(
            "div",
            { staticClass: "input_group d-flex align-center mt-20" },
            [
              _c("search-module", {
                staticClass: "mr-10",
                attrs: { props_keyword: _vm.props_keyword },
                on: { handleChange: _vm.changeKeyword },
              }),
              _c(
                "div",
                {
                  staticClass: "button search",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleSearchButton()
                    },
                  },
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("category-filter", {
        staticClass: "mt-10",
        attrs: {
          only: _vm.only,
          type_value: _vm.props_type,
          price_type: _vm.props_price_type,
        },
        on: { handlePrice: _vm.handlePrice, handleType: _vm.handleType },
      }),
      _c("result-page", {
        attrs: {
          type: _vm.type,
          package_list: _vm.package_list,
          task_list: _vm.task_list,
        },
      }),
      _c("paging"),
      _vm.load_more
        ? _c(
            "div",
            {
              staticClass:
                "load_more cp un_sel d-flex align-center justify-center",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadingMore()
                },
              },
            },
            [
              _vm.loading
                ? _c("loading-icon", { attrs: { size: "20px" } })
                : _vm._e(),
              !_vm.nomore && !_vm.loading
                ? _c("span", [_vm._v("加载更多")])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.nomore
        ? _c("div", { staticClass: "nomore" }, [
            _vm._v("————  已经到底啦  ————"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }