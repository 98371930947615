<template>
  <div
    class="views_shopping_mail_task_result bbox d-flex align-start flex-wrap"
  >
    <template
      v-if="type==='teaching_package'"
    >
      <template
        v-for="(item,index) in package_list"
      >
        <teaching-package
          @click.stop.native="clickPackage(item)"
          class="teaching_package mb-10"
          :key="index"
          :ppackage="item"
        ></teaching-package>
      </template>
    </template>
    <template
      v-if="type==='task'"
    >
      <template
        v-for="(item,index) in task_list"

      >
        <task-module
          class="mr-16 task_module mb-16"
          :key="index"
          :ptask="item"
          @click.stop.native="viewTask(item)"
        ></task-module>
      </template>
    </template>
  </div>
</template>

<script>
import TeachingPackage from '@/views/ShoppingMail/Modules/TeachingPackage/Unit/Major.vue';
import TaskModule from '@/views/ShoppingMail/Modules/Task/Unit/Major.vue';
export default {
  components: {
    TeachingPackage,
    TaskModule,
  },
  props: {
    type: {
      type: String,
      default: 'teaching_package',
    },
    package_list: {
      type: Array,
      default: ()=>{
        return [];
      }
    },
    task_list: {
      type: Array,
      default: ()=>{
        return [];
      }
    },
  },
  methods: {
    clickPackage(item){
      this.$api.shopping_mail.pushNav({type: 'teaching_package', package_data: item});
    },
    viewTask(item){
      this.$api.shopping_mail.pushNav({type: 'task', task_data: item, payment: true});
    },
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_task_result{
  width: 100%;
  .teaching_package{
    width: 18.9%;
    margin-right: 1.32%;
    &:nth-of-type(5n){
      margin-right: 0;
    }
  }
}
</style>
